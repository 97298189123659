import { HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Configuration, DailyEffort as DailyEffortDTO, DailyeffortsService } from 'generated-sources';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DailyEffort, Model } from 'src/app/shared/models';
import { HttpService } from './http';
import { ResourceService } from './resource.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class EffortsService extends ResourceService<DailyEffort, DailyEffortDTO, DailyEffort, DailyEffortDTO> {
  private readonly proxy: DailyeffortsService;

  private readonly eventMapper = tap<DailyEffort[]>((response) => {
    response.forEach((e) => {
      this.effortChanged.emit(e);
    });
  });

  constructor(
    http: HttpService,
    private readonly userService: UserService,
  ) {
    super(http);
    this.proxy = new DailyeffortsService(http.asClient, this.backend, new Configuration());
  }

  @Output() effortChanged = new EventEmitter<DailyEffort>();

  private get userId() {
    return this.userService.getLoggedInUser().id;
  }

  protected get name(): string {
    return 'effort';
  }

  create(model: DailyEffort): Observable<DailyEffort> {
    const dto = this.modelToDto(model);
    return this.proxy.createDailyeffort(dto).pipe(this.mapSingle);
  }

  get(id: number): Observable<DailyEffort> {
    return this.proxy.getDailyeffortById(id).pipe(this.mapSingle);
  }

  update(id: number, model: DailyEffort): Observable<DailyEffort> {
    const dto = this.modelToDto(model);
    return this.proxy.updateDailyeffort(id, dto).pipe(this.mapSingle);
  }

  delete(id: number): Observable<unknown> {
    return this.proxy.deleteDailyeffort(id);
  }

  protected modelToDto(effort: DailyEffort): DailyEffortDTO {
    return {
      id: effort.id,
      day: typeof effort.day === 'string' ? effort.day : Model.formatDate(effort.day),
      minutes: effort.minutes,
      assignmentId: effort.assignmentId,
      submittedByDate: effort.submittedByDate ? Model.formatDate(effort.submittedByDate) : null,
      approvedByDate: effort.approvedByDate ? Model.formatDate(effort.approvedByDate) : null,
      workedFromHome: effort.workedFromHome,
    };
  }

  protected dtoToModel(a: DailyEffortDTO): DailyEffort {
    return new DailyEffort(a);
  }

  protected listDtoToModel(a: DailyEffortDTO): DailyEffort {
    return new DailyEffort(a);
  }

  getMyEffortsForTimespan(
    from: moment.Moment = moment().weekday(0).hour(0).minute(0),
    until: moment.Moment = moment().weekday(0).hour(0).minute(0).add(6, 'days'),
  ): Observable<DailyEffort[]> {
    const fromString = from.format(this.dateFormat);
    const untilString = until.format(this.dateFormat);
    return this.proxy.getMineForTimespan(fromString, untilString).pipe(this.mapList);
  }

  submitEfforts(efforts: DailyEffort[]): Observable<Array<DailyEffort>> {
    const dtos = efforts.map((e) => this.modelToDto(e));
    return this.proxy.submitDailyefforts(dtos).pipe(this.mapList).pipe(this.eventMapper);
  }

  approveEfforts(efforts: DailyEffort[], userId: number = this.userId): Observable<Array<DailyEffort>> {
    const dtos = efforts.map((e) => this.modelToDto(e));
    return this.proxy.approveForEmployee(userId, dtos).pipe(this.mapList).pipe(this.eventMapper);
  }

  resetEfforts(efforts: DailyEffort[], userId: number = this.userId): Observable<Array<DailyEffort>> {
    const dtos = efforts.map((e) => this.modelToDto(e));
    return this.proxy.resetForEmployee(userId, dtos).pipe(this.mapList).pipe(this.eventMapper);
  }

  downloadEfforts(
    from: Moment,
    until: Moment,
    employees: number[],
    projects: number[],
    granularity: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR',
  ): void {
    this.exportEfforts(from, until, employees, projects, granularity).subscribe((response: HttpResponse<Blob>) => {
      if (response.status === 200) {
        const disposition = response.headers.get('content-disposition');
        const escapedFilename = disposition.split(';')[1].split('=')[1];
        const filename = escapedFilename.replace(/"/g, '');

        const fileUrl = window.URL.createObjectURL(response.body);

        const link = document.createElement('a');
        link.download = filename;
        link.href = fileUrl;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();

        setTimeout(() => window.URL.revokeObjectURL(fileUrl), 100);
      }
    });
  }

  exportEfforts(
    from: Moment,
    until: Moment,
    employees: number[],
    projects: number[],
    granularity: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR',
  ): Observable<unknown> {
    const requestFrom = from.format(this.dateFormat);
    const requestUntil = until.format(this.dateFormat);

    return this.proxy.exportDailyEfforts(
      requestFrom,
      requestUntil,
      employees,
      projects,
      granularity,
      'EXCEL',
      'response',
      false,
    );
  }
}
