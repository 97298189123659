import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'DecimalToHours',
})
export class DecimalToHoursPipe implements PipeTransform {
  transform(value?: number | null, hideZeroMinutes = false): string {
    if (!value) {
      return '';
    }
    const hours = Math.trunc(value / 60);
    const minutes = Math.trunc(value - hours * 60);
    if (hideZeroMinutes && minutes === 0) {
      return `${hours}`;
    } else {
      const minutePart = `0${minutes}`.slice(-2);
      return `${hours}:${minutePart}`;
    }
  }
}
