<h2 translate>profile.title</h2>

<div class="header-wrapper">
  <mat-accordion *ngIf="employee" multi>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title translate>profile.personalDetails</mat-panel-title>
        <mat-panel-description>
          <mat-icon color="primary">account_circle</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label>{{ 'profile.preferredName' | translate }}</mat-label>
        <input matInput [(ngModel)]="employee.nickName" name="nickname" readonly />
      </mat-form-field>
      <br />
      <mat-form-field>
        <mat-label>{{ 'profile.firstName' | translate }}</mat-label>
        <input matInput [(ngModel)]="employee.firstName" name="firstName" readonly />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'profile.lastName' | translate }}</mat-label>
        <input matInput [(ngModel)]="employee.lastName" name="lastName" readonly />
      </mat-form-field>
      <br />
      <mat-form-field class="formFieldWidth29 mat-mdc-form-field">
        <mat-label>{{ 'profile.email' | translate }}</mat-label>
        <input matInput type="email" [(ngModel)]="employee.email" name="email" readonly />
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel id="profile-info">
      <mat-expansion-panel-header>
        <mat-panel-title translate>profile.profileDetails</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label>{{ 'profile.username' | translate }}</mat-label>
        <input matInput [(ngModel)]="employee.userName" name="username" readonly />
      </mat-form-field>
      <div>
        <button mat-stroked-button color="primary" (click)="changePassword()">
          {{ 'profile.changePassword' | translate }}
        </button>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel id="profile-contract">
      <mat-expansion-panel-header>
        <mat-panel-title translate>contract.contractDetails</mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field>
        <mat-label>{{ 'contract.startDateContract' | translate }}</mat-label>
        <input matInput type="date" [(ngModel)]="employeeContractDate" name="contractDate" readonly />
      </mat-form-field>
      <mat-form-field *ngIf="!!employee.endDate">
        <mat-label>{{ 'contract.endDate' | translate }}</mat-label>
        <input matInput type="date" [(ngModel)]="employeeEndDate" name="endDate" readonly />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'contract.hoursOnContract' | translate }}</mat-label>
        <input matInput type="string" name="contractHours" [(ngModel)]="employee.contractHours" readonly />
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel id="profile-leave">
      <mat-expansion-panel-header>
        <mat-panel-title translate>hours.holidayHours</mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let field of holidayBalances">
        <mat-form-field appearance="fill">
          <mat-label>{{ field.description }}</mat-label>
          <input type="string" matInput [name]="field.name" [ngModel]="field.value" readonly />
        </mat-form-field>
        <br />
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>
