<h2 mat-dialog-title translate>news-popup.title</h2>

<mat-dialog-content *ngIf="newsItems && newsItems.length > 0">
  <div *ngFor="let newsItem of newsItems" class="news-item">
    <a href="{{ newsItem.webUrl }}" target="_blank" rel="noopener" (click)="onLinkClick(newsItem.id)">
      {{newsItem.title}}
    </a>
    <span> {{ newsItem.publishedOnDate | date }} </span>
    <mat-icon class="icon">open_in_new</mat-icon>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="!newsItems || newsItems.length === 0">
  <p translate>news-popup.empty</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="onNoClick()" translate>
    <mat-icon>close</mat-icon>
    Annuleren
  </button>
</mat-dialog-actions>
