import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { ExportEffortDialogComponent } from 'src/app/shared/components/dialogs/export-effort-dialog/export-effort-dialog.component';
import {
  Day,
  HoursOverviewDayEditDialogComponent,
} from 'src/app/shared/components/dialogs/hours-overview-day-edit-dialog/hours-overview-day-edit-dialog.component';
import { Assignment, DailyEffort, Employee } from 'src/app/shared/models';
import {
  AssignmentsService,
  EffortsService,
  EmployeesService,
  FeedbackService,
  UserService,
} from 'src/app/shared/services';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMMM YYYY',
  },
  display: {
    dateInput: 'MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

class DetailRow {
  assignment: Assignment;
  days: Day[] = [];
  print = true;

  public get total() {
    let total = 0;
    if (this.assignment)
      this.assignment.efforts.forEach((e) => {
        if (e.submitted) total += e.minutes;
      });
    return total;
  }
}

interface DayInMonth {
  date: number;
  isWeekend: boolean;
  daysInMonths: Moment;
}

@Component({
  selector: 'app-hours-overview-detail',
  templateUrl: './hours-overview-detail.component.html',
  styleUrls: ['./hours-overview-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class HoursOverviewDetailComponent implements OnInit {
  public params: { [x: string]: unknown; year?: number; month?: number; id?: number };
  public employee: Employee;

  today = Date.now();

  date = new UntypedFormControl(moment());
  countDays: DayInMonth[] = [];

  public rows: DetailRow[] = [];

  public editMode = false;

  private monthStart: Moment;
  private monthEnd: Moment;
  assignments: Assignment[] = [];

  constructor(
    private readonly feedback: FeedbackService,
    private readonly assignmentsService: AssignmentsService,
    private readonly employeesService: EmployeesService,
    private readonly dialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly effortsService: EffortsService,
    private readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: (params) => {
        this.params = params;
        this.employeesService.get(this.params.id).subscribe({
          next: (response) => {
            this.employee = response;
            this.fetchData();
          },
          error: (err: unknown) => {
            this.feedback.openErrorToast(err);
          },
        });
      },
    });
  }

  chosenYearHandler(normalizedYear: Moment): void {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment): void {
    const dateValue = this.date.value;
    dateValue.month(normalizedMonth.month());
    this.date.setValue(dateValue);

    this.monthStart = moment(normalizedMonth.startOf('month'));
    this.monthEnd = moment(normalizedMonth.endOf('month'));
    this.countDays = this.getMonthDates(normalizedMonth);

    this.fetch();
  }

  getMonthDates(month: Moment): DayInMonth[] {
    const result = [];
    const startDay = moment(this.monthStart);

    const dow = startDay.day();

    for (let i = 1; i <= month.daysInMonth(); i++) {
      const day = (dow + (i - 1)) % 7;
      result.push({
        date: i,
        isWeekend: day > 5 || day < 1,
        daysInMonths: moment(month).set('date', i),
      });
    }
    return result;
  }

  private fetchData() {
    const m = moment()
      .year(this.params.year)
      .month(this.params.month - 1);
    this.date.setValue(m);
    this.chosenMonthHandler(m);
  }

  public previousMonth(): void {
    const newValue = moment(this.date.value).subtract(1, 'months');
    this.navigateTo(newValue);
  }

  public thisMonth(): void {
    const newValue = moment().day(0).subtract(1, 'months');
    this.navigateTo(newValue);
  }

  public nextMonth(): void {
    const newValue = moment(this.date.value).add(1, 'months');
    this.navigateTo(newValue);
  }

  public navigateTo(newValue: Moment): void {
    const currentRoute = this.router.url.split('/');
    //update year
    currentRoute[currentRoute.length - 2] = `${newValue.year()}`;
    //update month
    currentRoute[currentRoute.length - 1] = `${newValue.month() + 1}`;

    this.router.navigate(currentRoute).then((e) => {
      if (e) {
        this.date.setValue(newValue);
        this.chosenMonthHandler(newValue);
      }
    });
  }

  public isSame(): boolean {
    return this.date.value.isSame(moment().day(0).subtract(1, 'months'), 'month');
  }

  private fetch() {
    this.assignmentsService
      .getAssignmentsForEmployee(this.employee.id, this.monthStart, this.monthEnd)
      .subscribe((assignments) => {
        this.assignments = assignments;
        this.rows = [];

        const content = this.assignments.filter((a) => a.efforts.length > 0);
        content.forEach((assignment) => this.addContentRow(assignment));

        this.initializePrintRow();
      });
  }

  private initializePrintRow() {
    const allInternal = this.rows.every((r) => r.assignment.project.customer.isInternal());
    this.rows.forEach((r) => {
      r.print = !r.assignment.project.customer.isInternal() || allInternal;
    });
  }

  addContentRow(assignment: Assignment): void {
    const row = new DetailRow();

    row.assignment = assignment;

    for (let index = 0; index < this.countDays.length; index++) {
      const date = moment(this.monthStart).add(index, 'days');
      const day = new Day();
      day.date = date;

      const effort = assignment.efforts.find((e) => e.day.isSame(date, 'day'));
      day.data =
        effort ||
        new DailyEffort({
          assignmentId: assignment.id,
          day: date.toISOString(),
          minutes: 0,
          workedFromHome: false,
        });

      row.days.push(day);
    }

    this.rows.push(row);
  }

  addEmptyRow(): void {
    const row = new DetailRow();

    for (let index = 0; index < this.countDays.length; index++) {
      const date = moment(this.monthStart).add(index, 'days');
      const day = new Day();
      day.data = new DailyEffort();
      day.date = date;
      row.days.push(day);
    }

    this.rows.push(row);
  }

  total(row: DetailRow): number {
    return row.total;
  }

  commitEfforts(row: DetailRow, assignment: Assignment): void {
    row.days.forEach((d) => {
      d.data.assignmentId = assignment.id;
    });
  }

  approveAll(): void {
    const efforts: DailyEffort[] = [];
    this.rows.forEach((r) => {
      r.days.forEach((day) => {
        if (day.data.id && !day.data.approved && day.data.submitted) efforts.push(day.data);
      });
    });

    if (efforts.length > 0) {
      this.effortsService.approveEfforts(efforts, this.employee.id).subscribe((response: DailyEffort[]) => {
        this.processDailyEffortResponse(response);

        this.feedback.openSuccessToast(
          `Uren voor ${this.monthStart.format('MMM')} ${this.monthStart.format('YYYY')} goedgekeurd`,
        );
      });
    } else {
      this.feedback.openNeutralToast();
    }
  }

  private processDailyEffortResponse(response: DailyEffort[]): void {
    this.rows.forEach((r) => {
      r.days.forEach(
        (d) => {
          const effort = response.find((e) => e.id === d.data.id);
          if (effort) {
            d.data = effort;
          }
        },
        (err: unknown) => {
          this.feedback.openErrorToast(err);
        },
      );
    });
  }

  reset(): void {
    const approvedEfforts: DailyEffort[] = [];
    this.rows.forEach((r) => {
      r.days.forEach((day) => {
        if (day.data.id && day.data.approved) approvedEfforts.push(day.data);
      });
    });

    if (approvedEfforts.length > 0)
      this.effortsService
        .resetEfforts(approvedEfforts, this.employee.id)
        .subscribe((response: DailyEffort[]) => this.processDailyEffortResponse(response));
  }

  print(): void {
    window.print();
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  showExportDialog(): Observable<unknown> {
    const data = {
      employee: this.employee,
      month: this.monthStart,
      callback: this.effortsService.downloadEfforts.bind(this.effortsService),
    };
    return this.feedback.dialog
      .open(ExportEffortDialogComponent, {
        width: '33%',
        data: data,
      })
      .afterClosed();
  }

  editEffort(day: Day, assignment: Assignment): void {
    if (!assignment) {
      this.feedback.openErrorToast('Er moet eerst een project geselecteerd worden');
      return;
    }

    day.editing = !day.editing;

    if (day.editing) {
      const dialogRef = this.dialog.open(HoursOverviewDayEditDialogComponent, {
        disableClose: true,
        data: day,
      });

      dialogRef.afterClosed().subscribe(() => {
        if (day.dirty) {
          const effort = day.data;

          if (effort.minutes === 0) {
            if (effort.id) {
              this.effortsService.delete(effort.id).subscribe(() => {
                day.data = new DailyEffort({
                  assignmentId: assignment.id,
                  day: day.date.toISOString(),
                  minutes: 0,
                  workedFromHome: false,
                });
                this.fetch();
              });
            }
          } else {
            effort.day = day.date;
            // uren zijn gewijzigd en moeten opnieuw worden goedgekeurd
            effort.approvedByDate = null;
            this.effortsService.createOrUpdate(effort).subscribe({
              next: (efforts: DailyEffort) => {
                day.data = efforts;
                day.modified = true;
                this.fetch();
              },
              error: (err: unknown) => {
                this.feedback.openErrorToast(err);
              },
            });
          }
        }
        day.editing = false;
      });
    }
  }

  get isAdministrator(): boolean {
    return this.userService.isAdministrator();
  }

  get isAccounting(): boolean {
    return this.userService.isAccounting();
  }

  get monthlyTotal(): number {
    let total = 0;
    for (const row of this.rows) {
      total += row.total;
    }
    return total;
  }

  hasSubmittedHours(day: DayInMonth): boolean {
    for (const row of this.rows) {
      if (row.assignment) {
        for (const effort of row.assignment.efforts) {
          if (effort.day.isSame(day.daysInMonths, 'day')) {
            return effort.submitted;
          }
        }
      }
    }
  }

  workedAtHome(day: DayInMonth): boolean {
    for (const row of this.rows) {
      if (row.assignment) {
        for (const effort of row.assignment.efforts) {
          if (effort.day.isSame(day.daysInMonths, 'day')) {
            return effort.workedFromHome;
          }
        }
      }
    }
    return false;
  }

  showWorkedAtLocationCheckbox(day: DayInMonth): boolean {
    for (const row of this.rows) {
      if (row.assignment) {
        for (const effort of row.assignment.efforts) {
          if (effort.day.isSame(day.daysInMonths, 'day')) {
            return true;
          }
        }
      }
    }
    return false;
  }

  countWorkingAtHome(): number {
    return this.rows.length === 0
      ? 0
      : this.rows
          .flatMap((row) => row?.assignment?.efforts)
          .filter(
            (effort) =>
              this.countDays.filter((day) => effort?.day.isSame(day, 'day')) &&
              effort?.workedFromHome &&
              effort?.submitted,
          ).length;
  }

  changeWorkAtHome(day: DayInMonth, evt: MatCheckboxChange): void {
    const efforts = this.rows.flatMap((row) => row.assignment.efforts);
    efforts.forEach((effort) => {
      if (effort.day.isSame(day.daysInMonths, 'day')) {
        effort.workedFromHome = evt.checked;
        this.effortsService.createOrUpdate(effort).subscribe({
          next: (updatedEffort) => {
            effort = updatedEffort;
          },
          error: (error) => {
            this.feedback.openErrorToast(error);
          },
        });
      }
    });
  }
}
