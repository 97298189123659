import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { Moment } from 'moment/moment';
import { DailyEffort } from 'src/app/shared/models';

export class Day {
  date: Moment;
  data: DailyEffort;
  editing = false;
  dirty = false;
  modified = false;

  public get weekend(): boolean {
    const weekday = this.date.day();
    return weekday === 0 || weekday === 6;
  }
}

@Component({
  selector: 'app-hours-overview-day-edit-dialog',
  templateUrl: './hours-overview-day-edit-dialog.component.html',
  styleUrls: ['./hours-overview-day-edit-dialog.component.scss'],
})
export class HoursOverviewDayEditDialogComponent implements OnInit {
  public dirty = false;
  public hours = 0;
  public submitted = false;
  public approved = false;

  constructor(
    public dialogRef: MatDialogRef<HoursOverviewDayEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public day: Day,
  ) {}

  ngOnInit(): void {
    const effort = this.day.data;

    this.day.dirty = false;
    this.hours = effort.minutes ? effort.minutes / 60 : 0;
    this.submitted = effort.submitted;
    this.approved = effort.approved;
  }

  submit(): void {
    const effort = this.day.data;
    effort.minutes = this.hours;

    if (!effort.submitted && this.submitted) effort.submittedByDate = moment();
    if (!effort.approved && this.approved) effort.approvedByDate = moment();

    if (!this.submitted) effort.submittedByDate = null;

    this.day.dirty = this.dirty;
    this.close();
  }

  change(): void {
    this.hours = Math.min(24, Math.max(-24, this.hours));
    const effort = this.day.data;
    const effortHours = effort.minutes ? effort.minutes / 60 : 0;
    this.dirty = this.hours !== effortHours || this.submitted !== effort.submitted;
  }

  cancel(): void {
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
