<h1 mat-dialog-title>Uren wijzigen</h1>

<mat-dialog-content>
  <mat-form-field>
    <mat-label>Uren</mat-label>
    <input
      matInput
      type="number"
      [(ngModel)]="hours"
      name="hours"
      (change)="change()"
      (keyup)="change()"
      maxlength="5"
      min="-24"
      max="24"
    />
  </mat-form-field>
  <mat-checkbox [(ngModel)]="submitted" (change)="change()" [disabled]="approved">Ingediend</mat-checkbox>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button type="submit" mat-raised-button (click)="cancel()">
    <mat-icon>close</mat-icon>
    Annuleren
  </button>
  <button type="submit" mat-raised-button color="primary" [disabled]="!dirty" (click)="submit()">
    <mat-icon>{{ day.data.id ? 'done' : 'add' }}</mat-icon>
    {{ day.data.id ? 'Opslaan' : 'Toevoegen' }}
  </button>
</mat-dialog-actions>
