import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Employee } from 'src/app/shared/models';

@Component({
  templateUrl: './employee-created-dialog.component.html',
})
export class EmployeeCreatedDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public readonly data: Employee) {}
}
