import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EmployeesService } from 'src/app/shared/services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  form = new FormGroup({ address: new FormControl('') });
  submitted: boolean;

  constructor(private readonly employeesService: EmployeesService) {}

  resetPassword(): void {
    this.employeesService.resetPassword(this.form.controls.address.value).subscribe({
      next: () => {
        this.submitted = true;
      },
      error: () => {
        // the backend would not inform us of errors, no need to handle them
        this.submitted = true;
      },
    });
  }
}
