import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Employee, Model } from 'src/app/shared/models';
import { EmployeesService, UserService } from 'src/app/shared/services';

interface HolidayBalance {
  description: string;
  value: number;
  name: string;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public employee: Employee;

  holidayBalances: HolidayBalance[] = [];

  @ViewChild('focus') nameField: ElementRef;

  constructor(
    private readonly employeesService: EmployeesService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
  ) {}

  get isAdministrator(): boolean {
    return this.userService.isAdministrator();
  }

  get employeeContractDate(): string {
    return Model.formatDate(this.employee.contractDate);
  }

  set employeeContractDate(value: string) {
    this.employee.contractDate = value && moment(value);
  }

  get employeeEndDate(): string {
    return Model.formatDate(this.employee.endDate);
  }

  set employeeEndDate(value: string) {
    this.employee.endDate = value && moment(value);
  }

  ngOnInit(): void {
    this.employee = this.userService.getLoggedInUser();
    if (this.employee?.id) {
      this.getEmployee();
    }
  }

  getEmployee(): void {
    this.employeesService.get(this.employee.id).subscribe((res) => {
      this.employee = res;
      this.userService.setLoggedInUser(res);
      this.updateCurrentHolidayHours();
    });
  }

  updateCurrentHolidayHours(): void {
    const yearly = this.employee.holidayHoursCurrentYear;
    const hoursCarriedForward = this.employee.holidayHoursPreviousYear || 0;
    const hoursApproved = this.employee.approvedHolidayHours || 0;
    const hoursSubmitted = this.employee.submittedHolidayHours || 0;

    const monthRate = (new Date().getMonth() + 1) / 12;
    const currentYearProRata = Math.floor(this.employee.holidayHoursCurrentYear * monthRate);
    const balanceProRata = hoursCarriedForward + currentYearProRata - hoursApproved;

    const holidayYear = this.employee.holidayYear;

    const t = (key: string, params?: object): string => this.translateService.instant(key, params);

    if (this.employee.holidayYear === moment().year()) {
      this.holidayBalances = [
        { name: 'holidayHoursCurrentYear', description: t('hours.holidayHoursCurrentYear'), value: yearly },
        {
          name: 'holidayHoursPreviousYear',
          description: t('hours.holidayHoursPreviousYear', { year: holidayYear - 1 }),
          value: hoursCarriedForward,
        },
        {
          name: 'holidayHoursCurrentYearProRata',
          description: t('hours.holidayHoursCurrentYearProRata'),
          value: currentYearProRata,
        },
        {
          name: 'approvedHolidayHours',
          description: t('hours.approvedHolidayHours'),
          value: hoursApproved,
        },
        {
          name: 'holidayBalanceRemainingProRata',
          description: t('hours.holidayBalanceRemainingProRata'),
          value: balanceProRata,
        },
        {
          name: 'submittedHolidayHours',
          description: t('hours.submittedHolidayHours'),
          value: hoursSubmitted,
        },
      ];
    } else {
      this.holidayBalances = [
        { name: 'holidayHoursCurrentYear', description: t('hours.holidayHoursCurrentYear'), value: yearly },
        {
          name: 'holidayHoursPreviousYear',
          description: t('hours.holidayHoursPreviousYear', { year: holidayYear - 1 }),
          value: hoursCarriedForward,
        },
        {
          name: 'holidayHoursCurrentYearProRata',
          description: t('hours.holidayHoursEndOfYear', { year: holidayYear }),
          value: yearly + hoursCarriedForward,
        },
        {
          name: 'approvedHolidayHours',
          description: t('hours.holidayHoursApprovedEndOfYear', { year: holidayYear }),
          value: hoursApproved,
        },
        {
          name: 'holidayBalanceRemainingProRata',
          description: t('hours.holidayHoursPreviousYear', { year: holidayYear }),
          value: yearly + hoursCarriedForward - hoursApproved,
        },
        {
          name: 'submittedHolidayHours',
          description: t('hours.submittedHolidayHours'),
          value: hoursSubmitted,
        },
      ];
    }
  }

  changePassword(): void {
    this.router.navigate(['medewerker', 'newPassword']);
  }
}
