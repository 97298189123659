<h2 mat-dialog-title>Uren exporteren</h2>

<mat-dialog-content>
  <form (ngSubmit)="onConfirm()" [formGroup]="contentForm">
    <mat-form-field>
      <input
        matInput
        [matDatepicker]="dateFrom"
        formControlName="dateFrom"
        name="dateFrom"
        placeholder="Van"
        readonly
        required
        (click)="dateFrom.open()"
      />
      <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
      <mat-datepicker #dateFrom></mat-datepicker>
      <mat-error *ngIf="contentForm.controls.dateFrom.errors?.required">Veld moet worden ingevuld</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        [matDatepicker]="dateUntil"
        formControlName="dateUntil"
        name="dateUntil"
        placeholder="Tot en met"
        readonly
        required
        (click)="dateUntil.open()"
      />
      <mat-datepicker-toggle matSuffix [for]="dateUntil"></mat-datepicker-toggle>
      <mat-datepicker #dateUntil></mat-datepicker>
      <mat-error *ngIf="contentForm.controls.dateUntil.errors?.required">Veld moet worden ingevuld</mat-error>
    </mat-form-field>
    <br />

    <mat-form-field>
      <mat-select
        id="employee-select"
        formControlName="employeesSelection"
        [compareWith]="hasEqualId"
        placeholder="Medewerkers"
        multiple
      >
        <mat-option>
          <ngx-mat-select-search
            formControlName="employeesFilterControl"
            placeholderLabel="Zoeken"
            noEntriesFoundLabel="Niets gevonden"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee"
          >{{employee.lastName}}, {{employee.firstName}}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <br />

    <mat-form-field>
      <mat-select
        id="project-select"
        formControlName="projectsSelection"
        [compareWith]="hasEqualId"
        placeholder="Projecten"
        multiple
      >
        <mat-option>
          <ngx-mat-select-search
            formControlName="projectsFilterControl"
            placeholderLabel="Zoeken"
            noEntriesFoundLabel="Niets gevonden"
          ></ngx-mat-select-search>
        </mat-option>

        <mat-optgroup *ngFor="let cwp of filteredProjectsByCustomer | async" [label]="cwp.customer.name">
          <mat-option *ngFor="let project of cwp.projects" [value]="project">{{project.name}}</mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
    <br />

    <mat-button-toggle-group formControlName="granularity" required>
      <mat-button-toggle value="DAY">Per dag</mat-button-toggle>
      <mat-button-toggle value="WEEK">Per week</mat-button-toggle>
      <mat-button-toggle value="MONTH">Per maand</mat-button-toggle>
    </mat-button-toggle-group>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="onDismiss()">
    <mat-icon>close</mat-icon>
    Annuleren
  </button>
  <button mat-raised-button color="primary" (click)="onConfirm()" [disabled]="!contentForm.valid">
    <mat-icon>get_app</mat-icon>
    Exporteren
  </button>
</mat-dialog-actions>
