import { Injectable } from '@angular/core';
import { HttpService } from './http';
import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';
import { MenuItem } from 'src/app/shared/models';
import { Configuration, MenuItem as MenuItemDTO, MenuService } from 'generated-sources';

@Injectable({ providedIn: 'root' })
export class CustomMenuItemsService extends ResourceService<MenuItem, MenuItemDTO, MenuItem, MenuItemDTO> {
  public proxy: MenuService;

  protected get name(): string {
    return 'menu';
  }

  constructor(private http: HttpService) {
    super(http);
    this.proxy = new MenuService(this.http.asClient, this.backend, new Configuration());
  }

  create(model: MenuItem): Observable<MenuItem> {
    const dto = this.modelToDto(model);
    return this.proxy.saveMenuItem(dto).pipe(this.mapSingle);
  }

  public update(id: number, model: MenuItem): Observable<MenuItem> {
    const dto = this.modelToDto(model);
    return this.proxy.saveMenuItem(dto).pipe(this.mapSingle);
  }

  get(id: number): Observable<MenuItem> {
    return this.proxy.getMenuItem(id).pipe(this.mapSingle);
  }

  getMenuItemsList(): Observable<Array<MenuItem>> {
    return this.proxy.getAllMenuItems().pipe(this.mapList);
  }

  delete(id: number): Observable<unknown> {
    return this.proxy.deleteMenuItem(id);
  }

  protected modelToDto(item: MenuItem): MenuItemDTO {
    return {
      id: item.id,
      name: item.name,
      url: item.url,
    };
  }

  protected dtoToModel(dto: MenuItemDTO): MenuItem {
    return new MenuItem(dto);
  }

  protected listDtoToModel(a: MenuItemDTO): MenuItem {
    return new MenuItem(a);
  }
}
