<div id="login">
  <mat-card class="mat-elevation-z8">
    <h1 translate>forgot-password.title</h1>

    <form (ngSubmit)="!form.invalid && resetPassword()" *ngIf="!submitted">
      <mat-form-field class="mat-mdc-form-field">
        <input
          type="email"
          name="email"
          matInput
          placeholder="{{ 'profile.email' | translate }}"
          required
          pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
          trim="blur"
          [formControl]="form.controls.address"
          [readonly]="submitted"
        />
        <mat-error translate>forgot-password.email.invalid</mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary" [disabled]="form.invalid || submitted">
        {{ 'forgot-password.reset' | translate }}
      </button>
    </form>

    <p *ngIf="submitted">
      {{ 'forgot-password.confirmation' | translate }}<br />
      <a routerLink="/login" translate>forgot-password.return-login</a>
    </p>
  </mat-card>
</div>
