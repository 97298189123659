import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentModule } from 'ngx-moment';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { MaterialModule } from '../../material.module';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

import { EmployeeCreatedDialogComponent } from 'src/app/shared/components/dialogs/employee-created-dialog/employee-created-dialog.component';
import { HoursOverviewDayEditDialogComponent } from './hours-overview-day-edit-dialog/hours-overview-day-edit-dialog.component';
import { NetworkDialogComponent } from './network-dialog/network-dialog.component';
import { NewsDialogComponent } from './news-dialog/news-dialog.component';

import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { AdminModule } from 'src/app/admin/admin.module';
import { SubmitHoursDialogComponent } from './submit-hours-dialog/submit-hours-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RestoreDialogComponent } from './restore-dialog/restore-dialog.component';
import { ExportEffortDialogComponent } from './export-effort-dialog/export-effort-dialog.component';
import { HolidayHoursDialogComponent } from './holiday-hours-dialog/holiday-hours-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

const components = [
  ConfirmDialogComponent,
  EmployeeCreatedDialogComponent,
  HoursOverviewDayEditDialogComponent,
  NetworkDialogComponent,
  NewsDialogComponent,
  SubmitHoursDialogComponent,
  RestoreDialogComponent,
  ExportEffortDialogComponent,
  HolidayHoursDialogComponent,
];

@NgModule({
  declarations: components,
  imports: [
    AdminModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MomentModule,
    MatBadgeModule,
    NgxTrimDirectiveModule,
    DragDropModule,
    NgxMatSelectSearchModule,
    TranslateModule,
  ],
  exports: components,
})
export class DialogsModule {}
