import { Injectable } from '@angular/core';
import {
  Configuration,
  Employee as EmployeeDTO,
  EmployeeCreate,
  EmployeeListObject as EmployeeListObjectDTO,
  EmployeesService as Swagger,
  PasswordNew,
  PasswordReset,
  PasswordSet,
} from 'generated-sources';
import moment from 'moment';
import { firstValueFrom, Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee, EmployeeListObject } from 'src/app/shared/models';
import { HttpService } from './http';
import { ResourceService } from './resource.service';

@Injectable({ providedIn: 'root' })
export class EmployeesService extends ResourceService<
  Employee,
  EmployeeDTO,
  EmployeeListObject,
  EmployeeListObjectDTO
> {
  private readonly proxy: Swagger;

  constructor(http: HttpService) {
    super(http);
    this.proxy = new Swagger(http.asClient, this.backend, new Configuration());
  }

  protected get name(): string {
    return 'employee';
  }

  create(employee: Employee): Observable<Employee> {
    const create: EmployeeCreate = this.modelToDto(employee);
    return this.proxy.createEmployee(create).pipe(this.mapSingle);
  }

  get(id: number): Observable<Employee> {
    return this.proxy.getEmployeeById(id).pipe(this.mapSingle);
  }

  getOrFetchAll(): Observable<EmployeeListObject[]> {
    return this.proxy.getAllEmployees().pipe(this.mapList);
  }

  getAll(): Observable<Array<EmployeeListObject>> {
    return this.proxy.getAllEmployees().pipe(this.mapList);
  }

  getArchived(): Observable<Array<EmployeeListObject>> {
    return this.proxy.getArchivedEmployees().pipe(this.mapList);
  }

  getAllIncludingArchived(): Observable<EmployeeListObject[]> {
    return zip(this.getAll(), this.getArchived()).pipe(map(([active, archived]) => [...active, ...archived]));
  }

  update(id: number, employee: Employee): Observable<Employee> {
    const dto = this.modelToDto(employee);
    return this.proxy.updateEmployees(id, dto).pipe(this.mapSingle);
  }

  archive(id: number): Observable<unknown> {
    return this.proxy.archiveEmployee(id);
  }

  restore(id: number): Observable<unknown> {
    return this.proxy.restoreEmployee(id);
  }

  protected modelToDto(employee: Employee): EmployeeDTO {
    return {
      id: employee.id,
      firstName: employee.firstName,
      lastName: employee.lastName,
      nickName: employee.nickName,
      userName: employee.userName,
      email: employee.email,
      contractDate: moment(employee.contractDate).format(this.dateFormat),
      endDate: employee.endDate ? moment(employee.endDate).format(this.dateFormat) : undefined,
      contractHours: employee.contractHours,
      holidayHoursCurrentYear: employee.holidayHoursCurrentYear,
      holidayHoursPreviousYear: employee.holidayHoursPreviousYear,
      approvedHolidayHours: employee.approvedHolidayHours,
      submittedHolidayHours: employee.submittedHolidayHours,
      holidayYear: employee.holidayYear,
      roles: employee.roles,
    };
  }

  protected dtoToModel(a: EmployeeDTO): Employee {
    return new Employee(a);
  }

  protected listDtoToModel(dto: EmployeeListObjectDTO): EmployeeListObject {
    return new EmployeeListObject(dto);
  }

  getLoggedInEmployee(): Promise<Employee> {
    return firstValueFrom(this.proxy.getEmployeeUserId().pipe(this.mapSingle));
  }

  resetPassword(address: string): Observable<unknown> {
    const dto: PasswordReset = {
      address: address,
    };
    return this.proxy.resetPassword(dto);
  }

  setPassword(password: string, token: string): Observable<unknown> {
    const dto: PasswordSet = {
      password: password,
      token: token,
    };
    return this.proxy.setPassword(dto);
  }

  setNewPassword(currentPassword: string, newPassword: string): Observable<unknown> {
    const dto: PasswordNew = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };
    return this.proxy.setNewPassword(dto);
  }
}
